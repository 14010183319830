const InitContactWidget = () => {
  const contactWidget = document.querySelector('.saf-contact-widget') as HTMLElement;

  document.addEventListener('scroll', () => {
    setTimeout(() => {
      contactWidget.classList.add('show');
    }, 1000)
  }, { once: true })
}

InitContactWidget()
